















































































































































































import { Component, Watch, Vue, Prop } from 'vue-property-decorator';
import { Status, IIdeaDto, IStatusDto, BusinessOwnerDto, ProductOwnerDto, BusinessDepartmentDto } from '@/service-proxies/service-proxies.g';
import { STATUS } from '@/components/status/status.vue';
import OverviewFiltersSelection from '@/utils/local-storage/overview-filters-selection';
const filtersSelection = new OverviewFiltersSelection();
import { actions } from '@/store/types';
import Permissions from '@/utils/helpers/permisssions';

interface IdeaVM extends IIdeaDto {
    _id: string;
    _title: string;
    _editUrl: string;
    _statusLabel: string;
    _statusColor: string;
    _scorePercentage: string;
    _scoreColor: string;
    _noActions: string;
}

const statusFilterHide = [
    'Killed',
    'Parked',
    'Draft'
]

const FilteringKeys = {
    BusinessOwner: 'business-owner',
    ProductOwner: 'product-owner',
    BusinessOwnerDepartment: 'business-department',
    Score: 'score',
    Status: 'status',
}

const SortingOptions = {
    Ascending: 'asc',
    Descending: 'desc',
    None: 'none'
}

@Component({
    name: 'projects',
})

export default class Projects extends Vue {
    $store: any;
    @Prop({default: null}) selectedPhaseId!: number;
    private mutablePhaseId: number | null = null;
    private Status = Status;

    private filterByStatuses: Status[] = [];
    private filterByScoreMin: number | null = null;
    private filterByScoreMax: number | null = null;
    private filterByCoodeids: string[] = []
    private filterByBusinessOwners: string[] = []
    private filterByProductOwners: string[] = []
    private filterByBusinessDepartments: string[] = []

    private sortObject: any

    private selectedSortingOption: string = SortingOptions.None

    private ideasLoaded = false
    private filtersLoaded = false

    private get Statuses(): any {
        const index = statusFilterHide.indexOf('Draft');
        if (!this.my) { // Show "Draft"
            if (index !== -1) {
                statusFilterHide.splice(index, 1);
            }
        } else {  // Hide "Draft"
            if (index == -1) {
                statusFilterHide.push('Draft')
            }
        }

        return Object.keys(Status).filter(k => !~statusFilterHide.indexOf(k) && isNaN(+k));
    }

    get paginationOptions(): any {
        const ItemsPerPage = [10, 20, 50, 100]
            .filter((item: number) => item < this.totalCount)

        if (ItemsPerPage.length === 0) {
            return [{
                label: this.totalCount.toString(),
                value: this.totalCount
            }];
        }

        return ItemsPerPage.map((item: number) => ({
            label: item.toString(),
            value: item
        }));
    }

    private currentPage = 1;
    private pageSize = 10;

    private initPageSize(): void {
        if (!this.paginationOptions.find((option: any) => option.value === this.pageSize)) {
            this.pageSize = this.paginationOptions[0].value;
        }
    }

    private get my(): boolean {
        return 'my' === this.$route.params.area;
    }

    public filterByPhaseId(): void{
        const statusFilters = this.getStatusFilters();
        const statuses = statusFilters.filter(statusFilter => statusFilter.phaseId === this.mutablePhaseId);
        this.filterByStatuses = [...statuses.map(statusFilter => statusFilter.value)];
        this.setFiltersSelection();
    }

    @Watch('selectedPhaseId')
    public onSelectedPhaseIdChanged(value: number): void {
        if(value) {
            this.mutablePhaseId = value;
            this.filterByPhaseId();
        }
    }

    get columns(): any {
        const columns = {
            coodeId: this.$t('projects.id'),
            title: this.$t('projects.use-case'),
            productOwner: this.$t('projects.productOwner'),
            businessOwner: this.$t('projects.businessOwner'),
            businessDepartmentName: this.$t('projects.businessOwnerDepartment'),
            statusId: this.$t('projects.status'),
            totalScore: this.$t('projects.score')
        }
        if (!this.ideas.some((idea: IdeaVM) => idea._noActions === ''))
            return columns;

        return {
            ...columns,
            actions: this.$t('projects.actions')
        }
    }

    get projectsVendorOptions(): any {
        return {
            options: {
                sortable: [],
                perPage: 100
            },
            columns: Object.values(this.columns),
            data: this.ideas
        }
    }

    private async getData(area = this.$route.params.area): Promise<void> {
        this.ideasLoaded = false

        this.filtersLoaded = false

        await this.$store.dispatch(actions.LOAD_IDEAS_LIST_POST, {
            full: true,
            onlyCurrentUser: area === 'my',
            pageNumber: this.currentPage,
            pageSize: this.pageSize > 10 ? this.pageSize : 10,
            term: this.$route.query.q ? String(this.$route.query.q) : '',
            sort: this.sortObject === [] ? undefined : this.sortObject,
            statusIds: this.filterByStatuses === [] ? undefined : this.filterByStatuses,
            businessOwners: this.filterByBusinessOwners === [] ? undefined : this.filterByBusinessOwners,
            productOwners: this.filterByProductOwners === [] ? undefined : this.filterByProductOwners,
            businessDepartmentIds: this.filterByBusinessDepartments === [] ? undefined : this.filterByBusinessDepartments,
            coodeIds: this.filterByCoodeids === [] ? undefined : this.filterByCoodeids,
            minScore: this.filterByScoreMin === null ? 0 : this.filterByScoreMin,
            maxScore: this.filterByScoreMax === null ? 100 : this.filterByScoreMax
        });
        this.initPageSize();
        if (this.$store.state.ideas.businessOwners.length !== 0 &&
            this.$store.state.ideas.businessDepartments.length !== 0 &&
            this.$store.state.ideas.productOwners.length !==0) {
            this.filtersLoaded = true
        }
        this.ideasLoaded = true
    }

    private async getOwners(): Promise<void> {
        await this.$store.dispatch(actions.GET_BUSINESS_OWNERS, {
            page: 1,
            size: 300,
            term: '',
        });

        await this.$store.dispatch(actions.GET_PRODUCT_OWNERS, {
            page: 1,
            size: 300,
            term: '',
        });
    }

    private async getBusinessOwnersDepartments(): Promise<void> {
        await this.$store.dispatch(actions.GET_BUSINESS_OWNERS_DEPARTMENTS);
    }

    private async deleteIdea(ideaId: string): Promise<void> {
        if(confirm(this.$t('deleteConfirmationIdea') as string) ) {
            const deletedSuccessfully = await this.$store.dispatch(actions.DELETE_IDEA, ideaId)

            if (deletedSuccessfully) {
                this.getData()
            }
        }
    }

    get totalCount(): number {
        return this.$store.state.ideas.totalCount;
    }

    private get totalPages(): number {
        return this.$store.state.ideas.totalPages;
    }

    private async mounted(): Promise<void> {
        this.getFiltersSelection();
        await this.getOwners();
        await this.getBusinessOwnersDepartments();
        await this.getData();
        if (this.$refs['pui-filter']) {
            // eslint-disable-next-line
            // @ts-ignore
            this.$refs['pui-filter'].onAvailableWidthChanged(1000)
        }
        this.mutablePhaseId && this.filterByPhaseId();
    }

    private getFiltersSelection(): void {
        this.filterByProductOwners = filtersSelection.getProductOwners();
        this.filterByBusinessOwners = filtersSelection.getBusinessOwners();
        this.filterByBusinessDepartments = filtersSelection.getBusinessDepartments();
        this.filterByScoreMax = filtersSelection.getScoreMax();
        this.filterByScoreMin = filtersSelection.getScoreMin();
        this.filterByStatuses = filtersSelection.getStatuses();
    }

    @Watch('$route.params.area')
    onAreaChanged(to: any): void {
        this.currentPage = 1;
        this.filterByStatuses = [];
        this.getData(to);
    }

    @Watch('$route.query.q')
    onSearchQueryChanged(): void {
        this.getData();
        this.filterByPhaseId();
    }

    private draftIfNoTitle(idea: IIdeaDto, shortTitle: string): string {
        return idea && !(idea.title || '').length
            ? this.$t('draft') as string
            : shortTitle
    }

    get ideas(): IdeaVM[] {
        return this.$store.state.ideas.list.map((idea: IdeaVM) => {
            const status = this.$store.state.dashboard.statuses.find((status: IStatusDto) => status.id === idea.statusId)
            const label = status ? status.name : 'n/a'
            const title = idea.title || ''
            const shortTitle = title.length > 70 ? `${title.substr(0, 70)}...` : title;

            idea._title = label === 'Draft' ? this.draftIfNoTitle(idea, shortTitle) : shortTitle;
            idea._noActions = this.showActions(idea) ? '' : 'no-actions';
            idea._editUrl = `#/ideas/${idea.id}`;
            idea._id = idea.statusId === Status.Draft
                ? (this.$t('draft') as string)
                : idea.coodeId || `${idea.id}` || 'N/A'

            idea._statusLabel = label
            idea._statusColor = (STATUS as any)[label]
            idea._scoreColor = (idea.statusId || -1) > Status.Submitted ? idea._statusColor : (STATUS as any)['Draft']

            idea._scorePercentage = this.getScorePercentage(idea)

            return idea
        });
    }

    showActions(idea: IIdeaDto): boolean {
        return Permissions.canDeleteIdea(idea);
    }

    markIdeaAsRead(idea: IdeaVM): void {
        if (!idea.isRead) {
            this.$store.dispatch(actions.MARK_IDEA_AS_READ, idea.id)
        }
    }

    private getScorePercentage(idea: IdeaVM): string {
        return (idea.statusId || -1) > Status.Submitted
            ? `${idea.totalScorePercentage || 0}%`
            : `N/A`
    }

    private setPageSize(v: number): void {
        this.pageSize = v;
        this.onPageChanged();
    }

    private onPageChanged(): void {
        this.getData();
    }

    private gotoFirstPage(): void{
        this.currentPage = 1;
        this.getData();
    }

    private gotoLastPage(): void{
        this.currentPage = this.totalPages;
        this.getData();
    }

    private get sortingOptions(): { label: string; value: string }[] {
        return [
            {
                label: this.$t('selectSortingOption') as string,
                value: SortingOptions.None
            },
            {
                label: this.$t('sortBy') + ' ' + this.$t('coodeId') + ' ' + this.$t('ascending') as string,
                value: 'coodeId' + ':'  + SortingOptions.Ascending
            },
            {
                label: this.$t('sortBy') + ' ' + this.$t('coodeId') + ' '  + this.$t('descending') as string,
                value: 'coodeId' + ':'  + SortingOptions.Descending
            },
            {
                label: this.$t('sortBy') + ' ' + this.$t('byScore') + ' '  + this.$t('lowToHigh') as string,
                value: 'totalScorePercentage' + ':' + SortingOptions.Ascending
            },
            {
                label: this.$t('sortBy') + ' ' + this.$t('byScore') + ' '  + this.$t('highToLow') as string,
                value: 'totalScorePercentage' + ':' + SortingOptions.Descending
            }
        ]
    }

    private onUpdateSortingOption(sortBy: string): void {
        this.selectedSortingOption = sortBy

        if (sortBy.split(':').length == 2) {
            const sortingField = sortBy.split(':')[0]
            const sortingDirection = sortBy.split(':')[1]

            this.getSortedValues([ { field: sortingField, dir: sortingDirection } ])
        } else {
            this.getSortedValues(undefined)
        }
    }

    private filterValueChanged(e: Event): void {
        if (Object(e)[FilteringKeys.Score] !== undefined) {
            const min = Object(e)[FilteringKeys.Score]['min']
            const max = Object(e)[FilteringKeys.Score]['max']

            this.filterByScoreMin = min ?? null
            this.filterByScoreMax = max ?? null
        }

        this.filterByStatuses = Object(e)[FilteringKeys.Status] ? Object(e)[FilteringKeys.Status] : [];

        this.filterByBusinessOwners = Object(e)[FilteringKeys.BusinessOwner] ? Object(e)[FilteringKeys.BusinessOwner] : []
        this.filterByProductOwners = Object(e)[FilteringKeys.ProductOwner] ? Object(e)[FilteringKeys.ProductOwner] : []
        this.filterByBusinessDepartments = Object(e)[FilteringKeys.BusinessOwnerDepartment] ? Object(e)[FilteringKeys.BusinessOwnerDepartment] : []

        this.currentPage = 1;
        this.getData();
        this.setFiltersSelection();
    }

    private setFiltersSelection(): void {
        filtersSelection.setBusinessOwners(this.filterByBusinessOwners);
        filtersSelection.setProductOwners(this.filterByProductOwners);
        filtersSelection.setBusinessDepartments(this.filterByBusinessDepartments);
        filtersSelection.setScoreMin(this.filterByScoreMin);
        filtersSelection.setScoreMax(this.filterByScoreMax);
        filtersSelection.setStatuses(this.filterByStatuses);
    }

    private getSortedValues( sortObject: { field: string; dir: string }[] | undefined ): void {
        this.sortObject = sortObject
        this.getData()
    }

    private getStatusFilters(): { displayName: string; value: number; phaseId: number }[] {
        let statusesForFilters: { displayName: string; value: number; phaseId: number }[] = []

        statusesForFilters = this.$store.state.dashboard.statuses.filter((status: IStatusDto) =>
            status.name !== 'Draft'
        ).map((status: IStatusDto) => ({
            displayName: status.name,
            value: status.id,
            phaseId: status.phaseId,
        }))

        return statusesForFilters
    }

    private get businessOwnerFilters(): {displayName: string; value: string}[] {
        let businessOwnersForFilters: {displayName: string; value: string}[] = []

        businessOwnersForFilters = this.$store.state.ideas.businessOwners.map((businessOwner: BusinessOwnerDto) => ({
            displayName: businessOwner.name ? businessOwner.name : 'N/A',
            value: businessOwner.name ? businessOwner.name : 'N/A'
        }))

        return businessOwnersForFilters
    }

    private get businessOwnerDepartmentFilters(): {displayName: string; value: string}[] {
        let businessDepartmentForFilters: {displayName: string; value: string}[] = []

        businessDepartmentForFilters = this.$store.state.ideas.businessDepartments.map((businessDepartment: BusinessDepartmentDto) => ({
            displayName: businessDepartment.businessDepartmentName ? businessDepartment.businessDepartmentName : 'N/A',
            value: businessDepartment.id ? businessDepartment.id : 'N/A'
        }))

        return businessDepartmentForFilters
    }

    private get productOwnerFilters(): {displayName: string; value: string}[] {
        let productOwnersForFilters: {displayName: string; value: string}[] = []

        productOwnersForFilters = this.$store.state.ideas.productOwners.map((productOwner: ProductOwnerDto) => ({
            displayName: productOwner.name ? productOwner.name : 'N/A',
            value: productOwner.name ? productOwner.name : 'N/A'
        }))

        return productOwnersForFilters
    }

    private getMultiselectFilters(): {}[] {
        return [{
            name: FilteringKeys.ProductOwner,
            displayName: this.$t('byProductOwner'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Product Owner',
                options: this.productOwnerFilters
            },
            selectedValues: {
                options: this.filterByProductOwners
            },
            appliedValues: {
                options: this.filterByProductOwners
            }
        },
        {
            name: FilteringKeys.BusinessOwner,
            displayName: this.$t('byBusinessOwner'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Business Owner',
                options: this.businessOwnerFilters
            },
            selectedValues: {
                options: this.filterByBusinessOwners
            },
            appliedValues: {
                options: this.filterByBusinessOwners
            }
        },
        {
            name: FilteringKeys.BusinessOwnerDepartment,
            displayName: this.$t('byBusinessOwnerDepartment'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Business Owner Department',
                options: this.businessOwnerDepartmentFilters
            },
            selectedValues: {
                options: this.filterByBusinessDepartments
            },
            appliedValues: {
                options: this.filterByBusinessDepartments
            }
        },
        {
            name: FilteringKeys.Score,
            displayName: this.$t('byScore'),
            type: 'numericalrange',
            isExpandable: true,
            isExpanded: true,
            config: {
                unit: '%',
                step: 1,
                boundaries: {
                    min: {
                        bound: 0,
                        label: {
                            short: this.$t('scoreMinShort'),
                            long: this.$t('scoreMinLong')
                        }
                    },
                    max: {
                        bound: 100,
                        label: {
                            short: this.$t('scoreMaxShort'),
                            long: this.$t('scoreMaxLong')
                        }
                    }
                }
            },
            selectedValues: {
                min: this.filterByScoreMin,
                max: this.filterByScoreMax
            },
            appliedValues: {
                min: this.filterByScoreMin,
                max: this.filterByScoreMax
            }
        },
        {
            name: FilteringKeys.Status,
            displayName: this.$t('byStatus'),
            type: 'multiselect',
            isExpandable: true,
            isExpanded: true,
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Status',
                options: this.getStatusFilters()
            },
            selectedValues: {
                options: this.filterByStatuses
            },
            appliedValues: {
                options: this.filterByStatuses
            }
        },
        ]
    }
}
