export default class OverviewFiltersSelection {
    private readonly productOwnersKey: string = 'filtersSelectionOverview.productOwners';
    private readonly businessOwnersKey: string = 'filtersSelectionOverview.businessOwners';
    private readonly businessDepartmentsKey: string = 'filtersSelectionOverview.businessDepartments';
    private readonly scoreMinKey: string = 'filtersSelectionOverview.scoreMin';
    private readonly scoreMaxKey: string = 'filtersSelectionOverview.scoreMax';
    private readonly statusesKey: string = 'filtersSelectionOverview.statuses';

    public setProductOwners(productOwners: Array<string>): void {
        localStorage.setItem(this.productOwnersKey, JSON.stringify(productOwners));
    }

    public getProductOwners(): Array<string> {
        const productOwnersResult = localStorage.getItem(this.productOwnersKey);
        if (!productOwnersResult) return [];
        return JSON.parse(productOwnersResult);
    }

    public setBusinessOwners(businessOwners: Array<string>): void {
        localStorage.setItem(this.businessOwnersKey, JSON.stringify(businessOwners));
    }

    public getBusinessOwners(): Array<string> {
        const businessOwnersResult = localStorage.getItem(this.businessOwnersKey);
        if (!businessOwnersResult) return [];
        return JSON.parse(businessOwnersResult);
    }

    public setBusinessDepartments(businessDepartments: Array<string>): void {
        localStorage.setItem(this.businessDepartmentsKey, JSON.stringify(businessDepartments));
    }

    public getBusinessDepartments(): Array<string> {
        const businessDepartmentssResult = localStorage.getItem(this.businessDepartmentsKey);
        if (!businessDepartmentssResult) return [];
        return JSON.parse(businessDepartmentssResult);
    }

    public setScoreMin(scoreMin: number | null): void {
        if (scoreMin === null)
            localStorage.setItem(this.scoreMinKey, JSON.stringify(''));

        localStorage.setItem(this.scoreMinKey, JSON.stringify(scoreMin));
    }

    public getScoreMin(): number | null {
        const scoreMinResult = localStorage.getItem(this.scoreMinKey);
        return this.validateNumber(scoreMinResult);
    }

    public setScoreMax(scoreMax: number | null): void {
        if (scoreMax === null)
            localStorage.setItem(this.scoreMaxKey, JSON.stringify(''));

        localStorage.setItem(this.scoreMaxKey, JSON.stringify(scoreMax));
    }

    public getScoreMax(): number | null {
        const scoreMaxResult = localStorage.getItem(this.scoreMaxKey);
        return this.validateNumber(scoreMaxResult);
    }

    public setStatuses(statuses: Array<any>): void {
        localStorage.setItem(this.statusesKey, JSON.stringify(statuses));
    }

    public getStatuses(): Array<any> {
        const statusesResult = localStorage.getItem(this.statusesKey);
        if (!statusesResult) return [];
        return JSON.parse(statusesResult);
    }

    private validateNumber(value: string | null): number | null {
        if (!value || value === 'null') return null;
        return Number(value);
    }

    public clearStatusFilter(): void {
        localStorage.setItem(this.statusesKey, JSON.stringify([]));
    }
}
