











import { Component, Vue, Prop } from 'vue-property-decorator';

export const STATUS = {
    'Draft': '#D6D6D6',
    'Submitted':'#00B0FF',
    'Onboarded':'#28359380',
    'Evaluated':'#283593',
    'Use Case Prioritized':'#fa8cb3',
    'Delivery Approved': '#F50057',
    'Delivery prepared':'#f50057',
    'In PoC':'#FF6F00',
    'In MVP': '#96ffcc',
    'In MP': '#00E676',
    'In Implementation':' #00BFA5',
    'In Use': '#607D8B',
    'Parked':'#bfcbd1'
}

export const PHASE = {
    'New Ideas Submitted': '#00B0FF',
    'Ideas Evaluated': '#283593',
    'Prioritized Use Cases': '#F50057',
    'In PoC': '#FF6F00',
    'In Delivery': '#00E676',
    'In Implementation': '#00BFA5',
    'In Use': '#607D8B',
    'Parked': '#BFCBD1',
}

/*
    1 = Draft                   1. Draft
    ---------------------------------------------------------------------------------------
    2 = Submitted               2. Submitted                          New Ideas Submitted                    >>> Screening (Review) + Stake Holders
    ---------------------------------------------------------------------------------------
    3 = Evaluated               3. Evaluated                          Ideas Evaluated                        >>> Prioritize/Ease of implementation Score
    4 = Prioritised             4. Prioritized                                                                 >>> Prioritize/Benefits Score
    ---------------------------------------------------------------------------------------
    5 = Approved                5. Prioritised                        Prioritised Development Backlog   >>>
                                6. Delivery Approved

    6 = Prototype               7. Delivery prepared
    ---------------------------------------------------------------------------------------
    7 = In PoC                  8.Proof of concept completed                 Proof of Concept
    ---------------------------------------------------------------------------------------
    8 = MVP                     9. MVP completed                      Development / Implementation

    9 = Rollout                 10. Mature product / solution developed
    ---------------------------------------------------------------------------------------
    10 = In Implementation      11. In Implementation                 In Implementation
    ---------------------------------------------------------------------------------------
    11 = InUse                  12. In Use                          In Use
    ---------------------------------------------------------------------------------------
    12 = Parked                 12. Parked
*/
export const RING = {
    // Bottom slice (lower): New Ideas Submitted
    2: [2, 2, 3, 3, 3],
    // Bottom slice (upper): Ideas Evaluated
    3: [0, 1, 1], 4: [0, 1, 1],

    // Prioritised Development Backlog
    5: [3], 6: [3], 7: [3],

    // In PoC
    8: [2],

    // Development / Implementation
    9: [1], 10: [1],

    // In Implementation
    11: [0]
}

export const plottedStatuses = [4, 5, 6, 7, 8, 9, 10, 11, 2, 3]

@Component({
    name: 'status',
})
export default class Status extends Vue {
    @Prop()
    private statusid!: number;

    @Prop()
    private statuses!: any[];

    private get label(): string {
        const status = this.statuses.find(s => s.id === +this.statusid)
        return status && status.name;
    }

    private get color(): string {
        return (STATUS as any)[this.label];
    }
}

