var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filtersLoaded)?_c('div',{staticClass:"overview"},[_c('div',{staticClass:"filters-container"},[_c('span',[_c('pui-tooltip'),_c('pui-filter',{ref:"pui-filter",attrs:{"use-router":false,"config":{
                    filters: _vm.getMultiselectFilters()
                },"vuex-namespace":"ns1"},on:{"changed:applied-values":_vm.filterValueChanged}})],1),_c('pui-form-select',{staticClass:"sorting-dropdown",attrs:{"label":"sort","options":_vm.sortingOptions,"value":_vm.selectedSortingOption},on:{"change":_vm.onUpdateSortingOption}})],1),_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table-title mt-1 mb-1 ml-2",attrs:{"slot":"heading"},slot:"heading"},[_vm._v(" "+_vm._s(_vm.$t('use_cases'))+" ")]),(_vm.$store.state.dashboard.phases.length && !_vm.ideas.length && !_vm.$store.state.isLoading)?_c('pui-loader-error',{attrs:{"title":_vm.$t('noIdeaFound'),"icon":"error-empty-data"}}):_vm._e(),(_vm.$store.state.dashboard.phases.length && _vm.ideas.length)?_c('pui-table',{attrs:{"vendor-options":_vm.projectsVendorOptions},scopedSlots:_vm._u([{key:_vm.columns.coodeId,fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row._id)+" ")]}},{key:_vm.columns.title,fn:function(ref){
                var row = ref.row;
return [_c('pui-link',{class:['title', row._statusLabel],style:({ 'font-weight': row.isRead ? '' : 'bold', 'text-transform': 'none'}),attrs:{"title":row._title,"href":row._editUrl},on:{"click":function($event){return _vm.markIdeaAsRead(row)}}})]}},{key:_vm.columns.productOwner,fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.productOwner)+" ")]}},{key:_vm.columns.businessOwner,fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.businessOwner)+" ")]}},{key:_vm.columns.businessDepartmentName,fn:function(ref){
                var row = ref.row;
return [_vm._v(" "+_vm._s(row.businessDepartmentName)+" ")]}},{key:_vm.columns.statusId,fn:function(ref){
                var row = ref.row;
return [_c('span',{staticClass:"status",style:({'color': 'var(--status-color,' + row._statusColor + ')'})},[_c('pui-icon',{staticClass:"icon",attrs:{"icon-name":"circle","icon-color":row._statusColor,"size":"10"}}),_c('span',{staticClass:"text"},[_vm._v(" "+_vm._s(row._statusLabel)+" ")])],1)]}},{key:_vm.columns.totalScore,fn:function(ref){
                var row = ref.row;
return [_c('span',{staticClass:"status",style:({color: 'var(--status-color,' + row._scoreColor + ')'})},[_vm._v(" "+_vm._s(row._scorePercentage)+" ")])]}},{key:_vm.columns.actions,fn:function(ref){
                var row = ref.row;
return [_c('div',{class:['row__actions', 'flex-row', 'flex-center', row._noActions]},[_c('span',{staticClass:"button__action"},[_c('a',{attrs:{"href":row._editUrl}},[_c('pui-icon',{attrs:{"icon-name":"edit-notes","size":"30"}}),_vm._v(" "+_vm._s(_vm.$t('edit'))+" ")],1)]),_c('span',{staticClass:"button__action action-disabled"},[_c('div',{on:{"click":function($event){return _vm.deleteIdea(row.id)}}},[_c('pui-icon',{staticClass:"button__action-delete",attrs:{"icon-name":"delete","size":"30"}}),_vm._v(" "+_vm._s(_vm.$t('delete'))+" ")],1)])])]}}],null,true)}):_vm._e()],1),_c('div',{staticClass:"pagination flex-row flex-center mt-2 ml-2 mb-1"},[_c('div',{staticClass:"flex-row flex-center"},[_c('label',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('resultsPerPage'))+":")]),_c('pui-form-select',{staticClass:"page-size",attrs:{"label":"","value":_vm.pageSize,"search-input-placeholder":_vm.pageSize.toString(),"options":_vm.paginationOptions},on:{"change":_vm.setPageSize}}),_c('label',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('of'))+" "+_vm._s(_vm.totalCount))])],1),_c('div',{staticClass:"flex-row flex-center"},[(_vm.currentPage !== 1)?_c('span',{staticClass:"firstpage",on:{"click":_vm.gotoFirstPage}},[_vm._v(_vm._s(_vm.$t('firstPage')))]):_vm._e(),_c('pui-pagination',{attrs:{"total-pages":_vm.totalPages,"range-of-pages":9},on:{"changed:page":_vm.onPageChanged},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),(_vm.currentPage !== _vm.totalPages)?_c('span',{staticClass:"lastpage mr-2",on:{"click":_vm.gotoLastPage}},[_vm._v(_vm._s(_vm.$t('lastPage')))]):_vm._e()],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }